import { navigate } from "gatsby";
import React, { useEffect } from "react";
import useLocalStorage from "use-local-storage";
import Loading from "../components/loading";
import { signOut } from "../firebase/firebase";

export default function SignOutPage() {
  const [isTokenLogIn, setIsTokenLogIn] = useLocalStorage<boolean | null>(
    "isTokenLogIn",
    null
  );
  useEffect(() => {
    signOut();
    if (isTokenLogIn) {
      setIsTokenLogIn(false);
      navigate("/tokenLoggedOut");
    } else {
      navigate("/login");
    }
  }, []);

  return <Loading />;
}
